@import "~@angular/material/prebuilt-themes/indigo-pink.css";

/* You can add global styles to this file, and also import other style files */

/*================================================
Default CSS
=================================================*/
$body-font: 'Roboto', sans-serif;
$main-color: #FC8E41;
$title-color: #342E36;
$paragraph-color: #10142D;
$color-deep-sea: #F7F8FB;
$color-white: #ffffff;
$color-black: #000;

body {
    font-size: 16px;
    line-height: 1.8;
    font-family: $body-font;
    color: $paragraph-color;
    font-weight: 400;
}

p {
    color: $paragraph-color;
}

a {
    display: inline-block;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: none;
    }
}

button {
    margin: 0;
    padding: 0;
    outline: 0;

    &:focus {
        outline: 0;
        border: 0;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 600;
    line-height: 1.4;
    color: $paragraph-color;
}

h3 {
    font-size: 24px;
}

.d-table {
    width: 100%;
    height: 100%;
}

.d-table-cell {
    display: table-cell;
    vertical-align: middle;
}

img {
    max-width: 100%;
}

.ptb-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.pt-100 {
    padding-top: 100px;
}

.pt-80 {
    padding-top: 80px;
}

.ptb-70 {
    padding-top: 70px;
    padding-bottom: 70px;
}

.pb-100 {
    padding-bottom: 100px;
}

.pb-70 {
    padding-bottom: 70px;
}

.pt-45 {
    padding-top: 45px;
}

.pt-20 {
    padding-top: 20px;
}

/*================================
Default btn Style
===================================*/
.default-btn {
    padding: 14px 27px;
    background-color: $main-color;
    color: $color-white;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    border: none;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 0;
        background-color: $paragraph-color;
        opacity: 0;
        transition: 0.7s;
        border-radius: 5px;
    }

    &:hover {
        background-color: $paragraph-color;
        color: $color-white;

        &::before {
            height: 100%;
            opacity: 1;
            border-radius: 5px;
        }
    }
}

/*================================
Section Title Style
===================================*/
.section-title {
    span {
        text-transform: capitalize;
        padding-bottom: 5px;
        font-weight: 400;
        color: $main-color;
        line-height: 0;
    }

    h2 {
        font-size: 35px;
        font-weight: 600;
        margin-top: 5px;
        line-height: 1.4;
        color: $paragraph-color;
    }

    p {
        padding-top: 10px;
        margin-bottom: 0;
    }

    .span-bg {
        line-height: 1.4;
        font-weight: 500;
        background-color: $main-color;
        padding: 5px 18px;
        color: $color-white;
        display: inline-block;
        margin-bottom: 5px;
    }
}

/*================================
Section Title Style End
===================================*/

/*================================
Top Header
===================================*/
.top-header {
    background-color: #f1f1f1;
}

.header-left {
    text-align: left;
    margin-top: 5px;

    .header-left-card {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                display: inline-block;
                text-align: left;
                position: relative;
                padding-left: 7px;
                color: $color-black;
                margin-right: 40px;
                padding-top: 15px;
                padding-bottom: 15px;

                &::before {
                    content: '';
                    position: absolute;
                    width: 1px;
                    height: 24px;
                    background-color: #645673;
                    left: -20px;
                    top: 16px;
                }

                &:first-child::before {
                    display: none;
                }

                &:last-child {
                    margin-right: 0;
                }

                .head-icon {
                    color: $color-black;
                    font-size: 20px;
                    text-align: center;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transition: .5s;
                    transform: translateY(-50%);
                }

                a {
                    display: inline-block;
                    color: $color-black;
                    font-size: 14px;
                    font-weight: 400;
                    margin-left: 25px;
                }

                &:hover {
                    .head-icon {
                        color: $color-black;
                    }

                    a {
                        color: $color-black;
                    }
                }
            }
        }
    }
}

.header-right {
    float: right;
}

.top-social-link {
    padding-top: 15px;
    padding-bottom: 10px;
    float: right;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: inline-block;
            margin-left: 7px;

            a {
                width: 30px;
                height: 30px;
                line-height: 33px;
                text-align: center;
                background-color: $main-color;
                color: $color-white;
                border-radius: 50px;

                &:hover {
                    background-color: $color-white;
                    color: $main-color;
                }
            }
        }
    }
}

/*================================
Top Header End
===================================*/

/*=================================
Navbar Area
====================================*/
.navbar-area {
    background-color: transparent;
    transition: 0.3s;
    position: relative;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    padding-left: 0;
}

.navbar-light {
    padding: 0;
    padding-left: 0;
    padding-right: 0;
    background-color: transparent;

    .navbar-brand {
        img {
            float: left;
            padding: 10px 0;
        }
    }

    .navbar-brand-sticky {
        display: none;
    }
}

/* Main nav */
.main-nav {
    position: inherit;
    background-color: #FFF6F1;
    top: 0;
    left: 0;
    z-index: inherit;
    padding: 0;
    width: 100%;
    height: auto;

    nav {
        .navbar-nav {
            .nav-item {

                &:hover a,
                .active {
                    color: $main-color !important;
                }

                a {
                    text-transform: capitalize;
                    color: $paragraph-color;
                    font-weight: 500;
                    margin-left: 12px;
                    margin-right: 12px;

                    i {
                        line-height: 0;
                        position: relative;
                        top: 3px;
                        font-size: 18px;
                    }

                    &:hover,
                    :focus {
                        color: $main-color !important;
                    }

                    &.active {
                        color: $main-color !important;
                    }

                    .active::before {
                        transform: scale(1);
                    }
                }

                &:hover .dropdown-menu {
                    transform: scaleX(1);
                }

                .dropdown-menu {
                    border: none;
                    padding: 0;
                    border-radius: 0;
                    background-color: $color-white !important;
                    transform: scaleX(0);

                    li {
                        border-bottom: 1px solid $color-white;

                        &:last-child {
                            border-bottom: none;
                        }

                        a {
                            text-transform: capitalize;
                            color: $paragraph-color !important;
                            position: relative;
                            transition: 0.7s;
                            font-weight: 500;
                            padding: 10px;
                            border-bottom: 1px dashed #ebebeb;

                            &.active {
                                border-radius: 0;
                                color: $main-color !important;
                                background-color: #f3f3f3;
                                border-left: 3px solid $main-color;
                            }

                            &:hover,
                            :focus,
                            .active {
                                color: $main-color !important;
                                border-radius: 0;
                                background-color: #f3f3f3;
                                border-left: 3px solid $main-color;
                            }
                        }

                        &.active {
                            color: $main-color !important;
                            background-color: #f3f3f3;
                            border-left: 3px solid $main-color;
                        }

                        .dropdown-menu {
                            left: 100%;
                            margin-top: 18px !important;
                            position: absolute;
                            transform: scaleX(0);
                        }

                        &:hover .dropdown-menu {
                            transform: scaleX(1);
                        }
                    }
                }
            }
        }
    }

    .others-options {
        .option-item {
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }

            .search-btn {
                font-size: 24px;
                margin-top: 10px;
                color: $main-color;
                transition: 0.5s;
                cursor: pointer;

                &:hover {
                    color: $paragraph-color;
                    transform: translateY(-5%);
                }
            }

            .close-btn {
                font-size: 24px;
                color: $main-color;
                transition: 0.5s;
                display: none;
                cursor: pointer;

                &:hover {
                    color: $paragraph-color;
                    transform: translateY(-5%);
                }

                &.active {
                    display: block;
                }
            }

            .add-cart-btn {
                .cart-btn-icon {
                    font-size: 24px;
                    color: $main-color;
                    position: relative;

                    &:hover {
                        color: $paragraph-color;
                    }

                    span {
                        position: absolute;
                        top: 5px;
                        right: -10px;
                        width: 15px;
                        height: 15px;
                        line-height: 15px;
                        background-color: $paragraph-color;
                        border-radius: 50%;
                        text-align: center;
                        color: $color-white;
                        font-size: 11px;
                    }
                }
            }
        }
    }
}

.nav-bar {
    background-color: $color-white;
}

.nav-btn {
    float: right;

    .default-btn {
        padding: 12px 20px;
    }
}

.search-overlay {
    display: none;

    &.search-popup {
        position: absolute;
        top: 100%;
        width: 300px;
        background: $color-white;
        z-index: 2;
        right: 0;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        margin-top: 0;

        .search-form {
            position: relative;

            .search-input {
                display: block;
                width: 100%;
                height: 50px;
                line-height: initial;
                border: 1px solid #eeeeee;
                color: $paragraph-color;
                outline: 0;
                transition: 0.5s;
                padding-top: 4px;
                padding-left: 10px;

                &:focus {
                    border-color: $main-color;
                }
            }

            .search-button {
                position: absolute;
                right: 0;
                top: 0;
                height: 50px;
                background: transparent;
                border: none;
                width: 50px;
                outline: 0;
                color: $paragraph-color;
                -webkit-transition: 0.5s;
                transition: 0.5s;
                padding: 0;

                &:focus {
                    color: $main-color;
                    ;
                }

                i {
                    font-size: 18px;
                    font-weight: bold;
                }
            }
        }
    }
}

.side-nav-responsive {
    display: none;

    .dot-menu {
        padding: 0 10px;
        height: 30px;
        cursor: pointer;
        z-index: 999;
        position: absolute;
        right: 60px;
        top: 12px;

        .circle-inner {
            display: flex;
            align-items: center;
            height: 30px;

            .circle {
                height: 5px;
                width: 5px;
                border-radius: 100%;
                margin: 0 2px;
                transition: 0.7s;
                background-color: $paragraph-color;
            }
        }

        &:hover {
            .circle-inner {
                .circle {
                    background-color: $main-color;
                }
            }
        }
    }

    .container {
        position: relative;
        display: flex;

        .container {
            position: absolute;
            top: 55px;
            right: 0;
            max-width: 185px;
            margin-left: auto;
            opacity: 0;
            visibility: hidden;
            transition: 0.7s;
            transform: scaleX(0);
            z-index: 2;
            padding-left: 15px;
            padding-right: 15px;
        }

        .container.active {
            opacity: 1;
            visibility: visible;
            transform: scaleX(1);
        }
    }

    .side-nav-inner {
        padding: 10px;
        box-shadow: 0 15px 40px rgba(0, 0, 0, 0.09);
        background-color: $color-white;

        .side-nav {
            background-color: $main-color;
            padding: 10px 0 2px;

            .side-item {
                padding-left: 10px;
                position: relative;
                display: inline-block;

                &:last-child {
                    padding-left: 0;
                }
            }

            .option-item {
                margin-right: 0;
                margin-left: 10px;
                display: inline-block;

                &:last-child {
                    margin-right: 20px;
                    margin-left: 5px;
                }

                .search-btn {
                    font-size: 30px;
                    margin-top: 10px;
                    color: $color-white;
                    transition: 0.5s;
                    cursor: pointer;

                    &:hover {
                        color: $main-color;
                        transform: translateY(-5%);
                    }
                }

                .close-btn {
                    font-size: 30px;
                    color: $color-white;
                    transition: 0.5s;
                    display: none;
                    cursor: pointer;

                    &:hover {
                        color: $main-color;
                        transform: translateY(-5%);
                    }

                    &.active {
                        display: block;
                    }
                }

                .add-cart-btn {
                    display: inline-block;

                    .cart-btn-icon {
                        font-size: 30px;
                        color: $color-white;
                        position: relative;
                        display: inline-block;

                        &:hover {
                            color: $paragraph-color;
                        }

                        span {
                            position: absolute;
                            top: 5px;
                            right: -10px;
                            width: 15px;
                            height: 15px;
                            line-height: 15px;
                            background-color: $paragraph-color;
                            border-radius: 50%;
                            text-align: center;
                            color: $color-white;
                            font-size: 11px;
                        }
                    }
                }
            }
        }
    }
}

.sticky-nav {
    top: 0;
    position: fixed;
    animation: 900ms ease-in-out 5s normal none 1 running fadeInDown;
    transition: 0.9s;
    width: 100% !important;
    z-index: 999;

    .navbar-light {
        .navbar-brand {
            img {
                max-width: 130px;
            }
        }
    }

    .main-nav {
        top: 0;
        background-color: #FFF6F1;
        position: fixed;
        z-index: 9999;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
    }

    .nav-bar {
        background-color: $color-white;
    }
}

/*=================================
Navbar Area End
====================================*/

/*=================================
Main Banner Area
====================================*/
.slider-area {
    position: relative;

    .owl-nav {
        margin-top: 0;

        .owl-prev {
            position: absolute;
            top: 87%;
            transform: translateY(-16px);
            left: 73%;
            color: $color-white !important;
            font-size: 20px !important;
            width: 40px;
            height: 40px;
            line-height: 40px;
            background-color: #707070 !important;

            &:hover {
                background-color: $main-color !important;
            }
        }

        .owl-next {
            position: absolute;
            top: 87%;
            transform: translateY(-16px);
            right: 18%;
            color: $color-white !important;
            font-size: 20px !important;
            width: 40px;
            height: 40px;
            line-height: 40px;
            background-color: #707070 !important;

            &:hover {
                background-color: $main-color !important;
            }
        }
    }
}

.pt-120 {
    padding-top: 120px;
}

.slider-item {
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 550px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #02213d66;
    }
}

.slider-content {
    padding-top: 50px;
    padding-bottom: 200px;
    position: relative;
    z-index: 1;

    span {
        color: $main-color;
    }

    h1 {
        margin-top: 10px;
        font-size: 50px;
        color: $color-white;
        font-weight: 700;
        margin-bottom: 25px;
        line-height: 1.3;
        max-width: 570px;
    }

    p {
        color: $color-white;
        margin-bottom: 5px;
        font-size: 18px;
        font-weight: 400;
        max-width: 539px;
        text-align: justify;
    }

    .slider-btn {
        .get-btn {
            padding: 12px 30px;
            border-radius: 3px;
            background-color: #ed1a23;
            color: $color-white;
            transition: 0.7s;

            &:hover {
                background-color: #ed1a23;
                color: $color-white;
            }
        }
    }
}

.bg-item1 {
    background-image: url(assets/img/home-one/home-one-1.jpg);
}

.bg-item2 {
    background-image: url(assets/img/home-one/home-one2.png);
}

.bg-item3 {
    background-image: url(assets/img/home-one/home-one3.png);
}

.bg-item4 {
    background-image: url(assets/img/home-one/quotes.JPG);
}

.bg-item5 {
    background-image: url(assets/img/home-one/repairs.JPG);
}

.banner-area {
    background-color: $color-deep-sea;
}

.banner-content {
    position: relative;
    z-index: 1;
    max-width: 630px;
    margin-left: auto;

    span {
        margin: 0;
        font-weight: 500;
        background-color: $main-color;
        padding: 5px 18px;
        color: $color-white;
        display: inline-block;
    }

    h1 {
        margin-top: 10px;
        font-size: 60px;
        color: $paragraph-color;
        font-weight: 700;
        margin-bottom: 25px;
        line-height: 1.3;
    }

    p {
        color: $paragraph-color;
        margin-bottom: 40px;
        font-size: 18px;
        font-weight: 400;
        max-width: 530px;
    }

    .banner-btn {
        .get-btn {
            padding: 12px 30px;
            background-color: $paragraph-color;
            color: $color-white;
            transition: 0.7s;

            &:hover {
                background-color: $main-color;
                color: $color-white;
            }
        }
    }
}

.banner-img {
    margin-top: 50px;
    margin-bottom: 50px;

    img {
        border-radius: 100px 0 0 100px;
    }
}

/*=================================
Main Banner Area End
====================================*/

/*================================
Inner Banner
==================================*/
.inner-banner {
    position: relative;
    background-position: center center;
    background-size: cover;

    padding: {
        top: 110px;
        bottom: 100px;
    }

    ;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        background-color: #000000;
        opacity: 0.65;
    }

    .inner-title {
        h3 {
            font-size: 40px;
            color: $color-white;
            font-weight: 700;
            position: relative;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            position: relative;

            li {
                font-size: 17px;
                color: $color-white;
                display: inline-block;

                i {
                    color: $color-white;
                    position: relative;
                    top: 3px;
                    margin: 0 3px;
                }

                a {
                    color: $color-white;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
}

.inner-bg1 {
    background-image: url(assets/img/inner-banner/About.jpg);
    object-fit: cover;
    object-position: top;
}

.inner-bg2 {
    background-image: url(assets/img/inner-banner/inner-banner-2.jpg);
}

.inner-bg3 {
    background-image: url(assets/img/inner-banner/inner-banner-3.jpg);
}

.inner-bg4 {
    background-image: url(assets/img/inner-banner/inner-banner-4.jpg);
}

.inner-bg5 {
    background-image: url(assets/img/inner-banner/inner-banner-5.jpg);
}

.inner-bg6 {
    background-image: url(assets/img/inner-banner/inner-banner-6.jpg);
}

.inner-bg7 {
    background-image: url(assets/img/inner-banner/our-team-view.png);
}

.inner-bg8 {
    background-image: url(assets/img/inner-banner/inner-banner-8.jpg);
}

.inner-bg9 {
    background-image: url(assets/img/inner-banner/inner-banner-9.jpg);
}

.inner-bg10 {
    background-image: url(assets/img/inner-banner/inner-banner-10.jpg);
}

.inner-bg11 {
    background-image: url(assets/img/inner-banner/inner-banner-11.jpg);
}

/*================================
Inner Banner End
==================================*/

.brand-area {
    background-color: $paragraph-color;
    padding-top: 50px;
    padding-bottom: 50px;
}

.brand-item {
    img {
        width: 115px !important;
    }
}

/*=================================
Choose Area
====================================*/
.choose-area {
    .section-title {
        h2 {
            max-width: 475px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.choose-card {
    position: relative;
    margin-bottom: 30px;
    background-color: $color-deep-sea;
    padding: 30px;
    border-radius: 5px;

    .choose-icon {
        position: absolute;
        top: 30px;
        left: 30px;
        width: 45px;
        height: 45px;
        font-size: 24px;
        line-height: 45px;
        color: $color-white;
        border-radius: 5px;
        text-align: center;
    }

    .content {
        margin-left: 65px;

        h3 {
            font-size: 24px;
            color: $paragraph-color;
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 15px;
            max-width: 235px;
        }

        .read-more-btn {
            text-align: center;
            display: inline-block;
            color: $paragraph-color;
            transition: 0.7s;
            border-radius: 5px;
            padding: 7px 25px 7px 45px;
            position: relative;
            font-size: 16px;
            font-weight: 600;

            .left-icon {
                display: inline-block;
                width: 35px;
                height: 35px;
                position: absolute;
                left: 0;
                top: 3px;
                line-height: 35px;
                background-color: $main-color;
                color: $color-white;
                border-radius: 5px;
                transition: 0.7s;
                font-size: 20px;
            }

            .right-icon {
                position: absolute;
                right: 20px;
                top: 10px;
                transition: 0.7s;
                opacity: 0;
                visibility: hidden;
                font-size: 20px;
                font-weight: normal;
            }

            &:hover {
                background-color: $main-color;
                color: $color-white;
                padding-left: 20px;
                padding-right: 40px;

                .left-icon {
                    opacity: 0;
                    visibility: hidden;
                }

                .right-icon {
                    color: $color-white;
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

.one-bg {
    background-color: #DE43A5;
}

.two-bg {
    background-color: #474DD5;
}

.three-bg {
    background-color: #FBCD03;
}

.choose-item {
    position: relative;
    margin-bottom: 30px;
    text-align: center;
    padding: 30px;
    background-color: $color-deep-sea;
    border-radius: 5px;

    .choose-item-icon {
        width: 45px;
        height: 45px;
        font-size: 24px;
        line-height: 45px;
        color: $color-white;
        border-radius: 5px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 24px;
        color: $paragraph-color;
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 15px;
        max-width: 235px;
        margin-left: auto;
        margin-right: auto;
    }

    .read-more {
        font-weight: 600;
        color: $paragraph-color;
        font-size: 16px;

        i {
            position: relative;
            top: 5px;
            font-size: 20px;
        }

        &:hover {
            color: $main-color;
            letter-spacing: 0.25px;
        }
    }
}

/*=================================
Choose Area End
====================================*/

/*=================================
About Area
====================================*/
.about-img {
    margin: 40px 40px 70px;
    position: relative;

    img {
        border-radius: 15px;
    }

    .about-img-shape {
        .shape-1 {
            position: absolute;
            top: -40px;
            right: -40px;
            z-index: -1;
        }

        .shape-2 {
            position: absolute;
            top: -40px;
            left: -40px;
            z-index: -1;
        }

        .shape-3 {
            position: absolute;
            bottom: -40px;
            left: -40px;
            z-index: -1;
        }
    }
}

.about-content {
    margin-bottom: 0;
    margin-left: 40px;

    .section-title {
        margin-bottom: 30px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            margin-bottom: 10px;

            i {
                color: $main-color;
                font-size: 20px;
                position: relative;
                top: 3px;
                margin-right: 10px;
            }
        }
    }
}

.about-img-2 {
    margin-bottom: 60px;
    margin-left: 30px;
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        top: 30px;
        width: 100%;
        height: 100%;
        left: -30px;
        background-color: $main-color;
        z-index: -1;
    }
}

/*=================================
About Area End
====================================*/

/*=================================
Service Area
====================================*/
.service-area {
    .owl-nav {
        margin-top: 0;

        .owl-prev {
            position: absolute;
            top: 50%;
            transform: translateY(-16px);
            left: -5%;
            color: $color-white !important;
            font-size: 20px !important;
            width: 40px;
            border-radius: 50px;
            height: 40px;
            line-height: 40px;
            background-color: #707070 !important;

            &:hover {
                background-color: $main-color !important;
            }
        }

        .owl-next {
            position: absolute;
            top: 50%;
            transform: translateY(-16px);
            right: -5%;
            color: $color-white !important;
            font-size: 20px !important;
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-radius: 50px;
            background-color: #707070 !important;

            &:hover {
                background-color: $main-color !important;
            }
        }
    }

    .section-title {
        h2 {
            max-width: 450px;
            margin-left: auto;
            margin-right: auto;
        }

        p {
            // max-width: 600px;
            // margin-left: auto;
            // margin-right: auto;
            line-height: 2.0;
        }
    }
}

.service-item {
    background-color: $color-deep-sea;
    padding: 30px 20px;
    text-align: center;
    margin-bottom: 30px;

    &:hover .read-more-btn {
        background-color: $main-color;
        color: $color-white;
        padding-left: 20px;
        padding-right: 40px;

        .left-icon {
            opacity: 0;
            visibility: hidden;
        }

        .right-icon {
            color: $color-white;
            opacity: 1;
            visibility: visible;
        }
    }

    .service-icon {
        width: 60px;
        height: 60px;
        line-height: 60px;
        font-size: 30px;
        border-radius: 50px;
        text-align: center;
        margin-bottom: 15px;
        color: $color-white;
        background-color: $main-color;
    }

    h3 {
        font-size: 24px;
        margin-bottom: 10px;

        a {
            display: block;
            color: $title-color;
        }
    }

    p {
        margin-bottom: 15px;
        max-width: 245px;
        margin-left: auto;
        margin-right: auto;
    }

    .read-more-btn {
        text-align: center;
        display: inline-block;
        color: $paragraph-color;
        transition: 0.7s;
        border-radius: 5px;
        padding: 7px 25px 7px 45px;
        position: relative;
        font-size: 16px;
        font-weight: 600;

        .left-icon {
            display: inline-block;
            width: 35px;
            height: 35px;
            position: absolute;
            left: 0;
            top: 3px;
            line-height: 35px;
            background-color: $main-color;
            color: $color-white;
            border-radius: 5px;
            transition: 0.7s;
            font-size: 20px;
        }

        .right-icon {
            position: absolute;
            right: 20px;
            top: 10px;
            transition: 0.7s;
            opacity: 0;
            visibility: hidden;
            font-size: 20px;
            font-weight: normal;
        }

        &:hover {
            background-color: $main-color;
            color: $color-white;
            padding-left: 20px;
            padding-right: 40px;

            .left-icon {
                opacity: 0;
                visibility: hidden;
            }

            .right-icon {
                color: $color-white;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.service-card {
    margin-bottom: 30px;
    cursor: pointer;

    &:hover {
        .content {
            width: 100%;
        }
    }

    a {
        display: block;
    }

    .content {
        position: relative;
        margin-top: -100px;
        padding: 30px;
        background-color: $paragraph-color;
        transition: 0.7s;
        width: 100%;

        i {
            display: inline-block;
            color: $main-color;
            font-size: 55px;
            margin-bottom: 15px;
            transition: 0.5s all ease;
            line-height: 1;
        }

        .service-icon-bg {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 110px;
            opacity: 0.2;
            transition: 0.5s all ease;
        }

        h3 {
            font-size: 22px;
            color: $color-white;
            margin-bottom: 10px;

            a {
                color: $color-white;
            }
        }

        p {
            margin-bottom: 0;
            color: $color-white;
        }
    }
}

.service-article {
    margin-bottom: 30px;

    .service-article-img {
        margin-bottom: 30px;
    }

    .service-article-content {
        h2 {
            font-size: 24px;
            margin-bottom: 15px;
        }

        p {
            margin-bottom: 20px;
        }
    }

    .service-article-list {
        margin-bottom: 30px;

        h2 {
            font-size: 24px;
            margin-bottom: 15px;
        }

        p {
            margin-bottom: 20px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: block;
                margin-bottom: 10px;

                i {
                    color: $main-color;
                    font-size: 20px;
                    position: relative;
                    top: 3px;
                    margin-right: 10px;
                }
            }
        }
    }

    .service-article-choose {
        h2 {
            font-size: 24px;
            margin-bottom: 15px;
        }

        p {
            margin-bottom: 20px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: block;
                margin-bottom: 10px;

                i {
                    color: $main-color;
                    font-size: 20px;
                    position: relative;
                    top: 3px;
                    margin-right: 10px;
                }
            }
        }
    }
}

.service-details-widget {
    margin-bottom: 35px;
    background-color: $color-deep-sea;

    .title {
        font-size: 20px;
        color: $paragraph-color;
        padding: 15px 0;
        font-weight: 600;
        position: relative;
        display: inline-block;
        margin-left: 30px;
        border-bottom: 3px solid $main-color;
    }

    .service-details-categories {
        padding: 10px 15px 20px;

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 10px;
                font-size: 15px;
                font-weight: 500;
                border-bottom: 1px solid #ededed;

                a {
                    display: inline-block;
                    color: $paragraph-color;
                    font-weight: normal;
                    padding: 7px 20px;
                    font-weight: 500;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }

    .service-widget-card {
        position: relative;
        margin-bottom: 30px;
        margin-left: 20px;

        i {
            color: $main-color;
            font-size: 35px;
            position: absolute;
            left: 0;
            top: 0;
        }

        .content {
            margin-left: 50px;

            h3 {
                font-size: 18px;
                margin-bottom: 10px;
                font-weight: 500;
            }

            p {
                margin-bottom: 0;
                color: $title-color;
                font-size: 15px;

                a {
                    display: block;
                    color: $title-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }

            span {
                margin-bottom: 0;
                color: $title-color;

                a {
                    display: block;
                    color: $title-color;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
}

/*=================================
Service Area End
====================================*/

/*=================================
Pricing Area
====================================*/
.pricing-area {
    position: relative;

    .section-title {
        margin-bottom: 75px;

        h2 {
            max-width: 460px;
            margin-left: auto;
            margin-right: auto;
        }

        p {
            max-width: 550px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.pricing-bg {
    background-color: $color-deep-sea;
}

.color-2 {
    color: $main-color;
}

.color-3 {
    color: $title-color;
}

.color-bg1 {
    background-color: #eaf3ff;
}

.color-bg2 {
    background-color: #fef4f0;
}

.color-bg3 {
    background-color: #f1f1f1;
}

.pricing-card {
    border: 1px dashed #94b7e2;
    padding: 5px;
    transition: 0.7s;
    margin-bottom: 30px;

    &:hover {
        border-color: $main-color;
    }

    .pricing-card-into {
        text-align: center;
        padding: 0 30px 30px;

        .pricing-icon {
            position: relative;
            margin-top: -40px;
            background-color: $color-white;
            box-shadow: 0 0 15px rgba(0, 0, 0, 0.03);
            width: 95px;
            height: 95px;
            font-size: 35px;
            border-radius: 50px;
            display: inline-block;
            text-align: center;
            line-height: 95px;
        }

        h3 {
            font-size: 20px;
            margin-bottom: 15px;
            margin-top: 15px;
            font-weight: 500;
            background-color: $color-white;
            padding: 10px;
        }
    }

    .price-rate {
        padding-bottom: 10px;
        border-bottom: 1px solid #b7d0ee;
        margin-bottom: 20px;

        h2 {
            font-size: 45px;
            line-height: 1;
            margin-bottom: 0;
        }

        span {
            font-size: 16px;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;

        li {
            display: block;
            color: $paragraph-color;
            margin-top: 3px;

            i {
                color: $main-color;
                font-size: 24px;
                position: relative;
                top: 3px;
            }

            del {
                color: $paragraph-color !important;
            }
        }
    }

    .purchase-btn {
        margin-top: 20px;
        padding: 10px 17px;
        color: $title-color;
        text-align: center;
        position: relative;
        overflow: hidden;
        z-index: 1;
        text-transform: capitalize;
        border-radius: 15px;
        border: none;
        outline: none;
        transition: 0.9s;
        font-weight: 600;
        font-size: 14px;
        border: 1px solid $title-color;

        &:hover {
            background-color: $main-color;
            border-color: $main-color;
            color: $color-white;
        }
    }
}

/*=================================
Pricing Area End
====================================*/

/*=================================
Product Area
====================================*/
.single-product {
    transition: all 0.5s;
    border: 1px dashed #eeeeee;
    margin-bottom: 30px;

    a {
        display: block;

        img {
            border-bottom: 1px solid #fc8e4154;
            text-align: center;
        }
    }

    .product-content {
        padding: 30px 20px;

        ul {
            list-style: none;
            padding: 0;
            line-height: 1;
            margin-bottom: 15px;

            li {
                display: inline-block;
                color: $main-color;
                padding: 0 5px;

                del {
                    color: $paragraph-color;
                }
            }
        }

        .rating {
            list-style: none;
            margin: 0 0 15px 0;
            padding: 0;
            float: right;

            li {
                color: $main-color;
                font-size: 14px;
                display: inline-block;
            }
        }

        h3 {
            text-transform: capitalize;
            margin-bottom: 0;
            transition: all 0.5s;
            font-size: 20px;

            a {
                color: $paragraph-color;

                &:hover {
                    color: $main-color;
                }
            }
        }

        .default-btn {
            &.product-btn {
                position: absolute;
                left: 0;
                right: 0;
                margin: auto;
                max-width: 180px;
                border-radius: 0;
                bottom: 200px;
                padding: 10px 20px;
                transform: scale(0);

                i {
                    font-size: 20px;
                    position: relative;
                    top: 3px;
                    margin-right: 5px;
                }
            }
        }
    }

    &:hover {
        background-color: $color-white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);

        .default-btn {
            &.product-btn {
                transform: scale(1);
            }
        }
    }
}

.product-detls-image {
    margin-bottom: 30px;
    border: 1px dashed #eeeeee;

    &:hover {
        border-color: $main-color;
    }
}

.product-desc {
    margin-bottom: 30px;

    h3 {
        margin-bottom: 12px;
        font-size: 24px;
        font-weight: 600;
    }

    .price {
        margin-bottom: 10px;
        font-size: 15px;
        font-weight: 600;

        .old-price {
            text-decoration: line-through;
            color: $paragraph-color;
            ;
            margin-left: 10px;
        }
    }

    .product-review {
        margin-bottom: 15px;

        .rating {
            display: inline-block;
            padding-right: 5px;
            font-size: 14px;

            i {
                color: $main-color;
            }
        }

        .rating-count {
            margin-left: 5px;
            display: inline-block;
            color: $paragraph-color;
            ;
            border-bottom: 1px solid $paragraph-color;
            ;
            line-height: initial;

            &:hover {
                color: $main-color;
                border-color: $main-color;
            }
        }
    }

    p {
        margin-bottom: 0;
    }

    .input-count-area {
        h3 {
            font-size: 16px;
            display: inline-block;
            font-weight: 500;
            margin-right: 15px;
        }

        .input-counter {
            margin-top: 10px;
            max-width: 130px;
            min-width: 130px;
            margin-right: 10px;
            text-align: center;
            display: inline-block;
            position: relative;
            margin-bottom: 15px;

            span {
                position: absolute;
                top: 2px;
                background-color: transparent;
                cursor: pointer;
                color: $paragraph-color;
                ;
                width: 50px;
                height: 100%;
                line-height: 45px;
                transition: 0.5s;

                &:hover {
                    color: $main-color;
                }
            }

            .minus-btn {
                left: 0;
            }

            .plus-btn {
                right: 0;
            }

            input {
                height: 45px;
                color: $paragraph-color;
                ;
                outline: 0;
                display: block;
                border: none;
                background-color: #f8f8f8;
                text-align: center;
                width: 100%;
                font-size: 18px;
                font-weight: 500;
            }
        }
    }

    .product-add-btn {
        margin-top: 20px;

        .default-btn {
            border: none;
            margin-right: 20px;
            border-radius: 0;
        }
    }

    .product-share {
        margin-top: 30px;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                margin: 0 3px;

                span {
                    color: $paragraph-color;
                    margin-right: 5px;
                    font-size: 16px;
                }

                a {
                    width: 35px;
                    height: 35px;
                    background-color: $main-color;
                    font-size: 16px;
                    color: $color-white;
                    line-height: 35px;
                    border-radius: 50px;
                    text-align: center;

                    &:hover {
                        background-color: $paragraph-color;
                        ;
                    }
                }
            }
        }
    }
}

.product .input-count-area .input-counter input::-webkit-input-placeholder {
    color: $paragraph-color;
    ;
}

.product .input-count-area .input-counter input:-ms-input-placeholder {
    color: $paragraph-color;
    ;
}

.product .input-count-area .input-counter input::-ms-input-placeholder {
    color: $paragraph-color;
    ;
}

.product .input-count-area .input-counter input::placeholder {
    color: $paragraph-color;
    ;
}

.product-tab {
    background-color: $color-deep-sea;
}

.products-details-tab {
    .tabs {
        margin: 0;
        padding: 0;
        list-style: none;
        border-bottom: 1px solid #cccccc;
        text-align: center;

        li {
            display: inline-block;
            line-height: initial;
            margin-right: 25px;

            a {
                display: inline-block;
                position: relative;
                font-size: 17px;
                font-weight: 600;
                color: $paragraph-color;
                padding-bottom: 15px;
                border-bottom: 1px solid #b1acac;

                span {
                    color: $main-color;
                }

                &:focus {
                    color: $main-color;
                    border-bottom: 1px solid $main-color;
                }
            }

            &.active a {
                color: $main-color;
                border-bottom: 1px solid $main-color;

                span {
                    color: $paragraph-color;
                }
            }

            &.current a {
                color: $main-color;
                border-bottom: 1px solid $main-color;

                span {
                    color: $paragraph-color;
                }
            }
        }
    }
}

.products-tabs-decs {
    max-width: 840px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;

    p {
        color: $paragraph-color;
        font-weight: 600;
        margin-bottom: 30px;
    }
}

.products-tabs-reviews {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            position: relative;
            padding-bottom: 40px;
            padding-left: 200px;
            margin-bottom: 30px;
            border-bottom: 1px solid #eeeeee;

            &:last-child {
                margin-bottom: 0;
            }

            img {
                position: absolute;
                top: 0;
                left: 90px;
            }

            h3 {
                margin-bottom: 5px;
                font-size: 20px;
                font-weight: 600;
                color: $paragraph-color;
            }

            .content {
                .rating {
                    display: inline-block;
                    color: #ee8100;
                    margin-right: 20px;
                    position: relative;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 3px;
                        right: -13px;
                        width: 1px;
                        height: 20px;
                        background-color: #cccccc;
                    }
                }

                span {
                    margin-bottom: 10px;
                    display: inline-block;
                }
            }

            p {
                margin-bottom: 0;
                max-width: 650px;
            }
        }
    }
}

.reviews-form {
    margin-top: 35px;

    .contact-form {
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;

        h3 {
            text-align: center;
            font-size: 24px;
            color: $paragraph-color;
            margin-bottom: 10px;
        }

        .form-group {
            .form-control {
                background-color: $color-white;
                border-radius: 0;
            }
        }
    }

    p {
        text-align: center;
        color: $paragraph-color;
        margin-bottom: 10px;
    }

    .rating {
        text-align: center;
        color: $main-color;
        position: relative;
        margin-bottom: 30px;
    }
}

/*=================================
Product Area End
====================================*/

/*=================================
Cart Wraps Area
===================================*/
.cart-wraps-area {
    .cart-table {
        table {
            margin-bottom: 0;

            thead {
                tr {
                    th {
                        border-bottom-width: 0px;
                        vertical-align: middle;
                        padding: 0 0 15px;
                        text-transform: uppercase;
                        border: none;
                        font-weight: 700;
                        font-size: 18px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        color: $paragraph-color;
                        padding-left: 0;
                        padding-right: 0;
                        font-size: 15px;
                        border-color: #eeeeee;
                        border-left: none;
                        border-right: none;

                        &.product-thumbnail {
                            a {
                                display: block;

                                img {
                                    width: 60px;
                                    height: 60px;
                                }
                            }
                        }

                        &.product-name {
                            a {
                                color: $paragraph-color;
                                font-weight: 600;
                                display: inline-block;
                                font-size: 16px;

                                &:hover {
                                    color: $main-color !important;
                                }
                            }
                        }

                        &.product-subtotal {
                            .remove {
                                color: $paragraph-color;
                                float: right;
                                position: relative;
                                top: 1px;
                                -webkit-transition: all 0.5s;
                                transition: all 0.5s;

                                i {
                                    font-size: 24px;
                                }

                                &:hover {
                                    color: $main-color;
                                }
                            }

                            span {
                                font-weight: 600;
                            }
                        }

                        &.product-quantity {
                            .input-counter {
                                max-width: 130px;
                                min-width: 130px;
                                text-align: center;
                                display: inline-block;
                                position: relative;

                                span {
                                    position: absolute;
                                    top: 0;
                                    background-color: transparent;
                                    cursor: pointer;
                                    color: $paragraph-color;
                                    width: 40px;
                                    height: 100%;
                                    line-height: 48px;
                                    transition: 0.5s;

                                    &:hover {
                                        color: $main-color !important;
                                    }
                                }

                                .minus-btn {
                                    left: 0;

                                    &:hover {
                                        color: $main-color !important;
                                    }
                                }

                                .plus-btn {
                                    right: 0;

                                    &:hover {
                                        color: $main-color !important;
                                    }
                                }

                                input {
                                    height: 45px;
                                    color: $paragraph-color;
                                    outline: 0;
                                    display: block;
                                    border: none;
                                    background-color: #f8f8f8;
                                    text-align: center;
                                    width: 100%;
                                    font-size: 17px;
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .cart-buttons {
        margin-top: 30px;
    }

    .cart-totals {
        background: $color-white;
        padding: 40px;
        box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
        border-radius: 0;
        margin: auto;
        margin-top: 50px;

        h3 {
            font-size: 24px;
            margin-bottom: 25px;
        }

        ul {
            padding: 0;
            margin: 0 0 25px;
            list-style-type: none;

            li {
                border: 1px solid #b8b08c;
                padding: 10px 15px;
                color: $paragraph-color;
                overflow: hidden;
                font-weight: 500;

                &:first-child {
                    border-bottom: none;
                }

                &:nth-child(3) {
                    border-top: none;
                }

                &:last-child {
                    border-top: none;
                }

                span {
                    float: right;
                    color: $paragraph-color;
                    font-weight: normal;
                }
            }
        }
    }

    .cart-calc {
        margin-top: 50px;
        background-color: $color-white;
        padding: 40px;
        box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);

        .cart-wraps-form {
            h3 {
                font-size: 24px;
                color: $paragraph-color;
                padding-bottom: 20px;
            }

            .form-group {
                margin-bottom: 20px;

                select {
                    height: 50px;
                    padding: 7px 18px;
                    color: #6c777d;
                    border: 1px solid #cfcfcf;

                    &:focus,
                    :hover {
                        outline: 0;
                        box-shadow: none;
                        border: 1px solid $main-color;
                        background-color: $paragraph-color !important;
                    }

                    option {
                        padding: 10px;

                        &:hover {
                            background-color: $paragraph-color !important;
                        }
                    }
                }

                .form-control {
                    font-size: 16px;
                    border: 1px solid #b8b08c;
                    color: #6c777d;
                    padding: 25px 18px;
                    font-weight: 400;

                    &:focus,
                    :hover {
                        outline: 0;
                        box-shadow: none;
                        border: 1px solid #cfcfcf;
                        ;
                    }
                }
            }
        }

        .nice-select {
            height: 50px;
            width: 100%;
            margin-bottom: 19px;
            border: 1px solid #b8b08c;

            .list {
                width: 100%;
            }

            .option {
                color: $paragraph-color !important;

                &:hover {
                    color: $color-white !important;
                    background-color: $main-color !important;
                }
            }

            .current {
                position: relative;
                top: 4px;
                font-weight: 500;
                color: #6c777d;
            }
        }
    }
}

.cart-wraps-area .nice-select .option.focus,
.cart-wraps-area .nice-select .option.selected.focus,
.cart-wraps-area .nice-select .option:hover {
    background-color: $paragraph-color !important;
    color: $color-white !important;
    font-weight: 600;
}

.cart-wraps-area .cart-calc select .option.focus,
.cart-wraps-area .cart-calc .nice-select .option.selected.focus {
    background-color: $paragraph-color !important;
    color: $color-white !important;
}

.cart-wraps-area .cart-table table tbody tr td.product-quantity .input-counter input::-webkit-input-placeholder {
    color: $main-color;
}

.cart-wraps-area .cart-table table tbody tr td.product-quantity .input-counter input:-ms-input-placeholder {
    color: $main-color;
}

.cart-wraps-area.cart-table table tbody tr td.product-quantity .input-counter input::-ms-input-placeholder {
    color: $main-color;
}

.cart-wraps-area .cart-table table tbody tr td.product-quantity .input-counter input::placeholder {
    color: $main-color;
}

.cart-wraps-area .cart-calc .cart-wraps-area form .form-control input::-webkit-input-placeholder {
    color: #6c777d;
}

.cart-area .cart-calc .cart-wraps-area form .form-control input:-ms-input-placeholder {
    color: #6c777d;
}

.cart-area .cart-area .cart-calc .cart-wraps-area .form-control input::-ms-input-placeholder {
    color: #6c777d;
}

.cart-area .cart-area .cart-calc .cart-wraps-area .form-control input::placeholder {
    color: #495057;
}

/*=================================
Cart Wraps Area End
===================================*/

/*=================================
Checkout Area
===================================*/
.checkout-area {
    .checkout-user {
        box-shadow: 0 5px 35px 0 rgba(0, 0, 0, 0.08);
        background: $color-white;
        padding: 20px 15px;
        margin-bottom: 65px;
        border-top: 3px solid $main-color;
        color: $main-color;

        span {
            color: $paragraph-color;
            font-size: 20px;

            a {
                color: $main-color;

                &:hover {
                    color: $paragraph-color;
                }
            }
        }
    }
}

.billing-details {
    margin-bottom: 30px;
    background-color: $color-white;
    padding: 50px 30px 25px 30px;
    box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);

    h3 {
        font-size: 24px;
        color: $paragraph-color;
        margin-bottom: 15px;
        font-weight: 600;
    }

    .form-group {
        margin-bottom: 25px;

        label {
            color: $paragraph-color;
            display: block;
            margin-bottom: 10px;
            font-weight: 500;

            span {
                color: $main-color;
            }
        }

        .form-control {
            height: 50px;
            color: #2d3652;
            border: 1px solid #e8e8e8;
            background-color: #fcfcff;
            border-radius: 0;
            padding: 10px 20px;
            width: 100%;

            &:focus,
            :hover {
                outline: 0;
                box-shadow: none;
                border: 1px solid $paragraph-color;
            }
        }

        .form-message {
            font-size: 16px;
            border: 1px solid #e8e8e8;
            background-color: #fcfcff;
            padding: 18px 18px;
            font-weight: 400;
            width: 100%;

            &:focus,
            :hover {
                outline: 0;
                box-shadow: none;
                border: 1px solid $paragraph-color;
            }
        }

        .nice-select {
            float: unset;
            line-height: 45px;
            color: $paragraph-color;
            padding-top: 0;
            padding-bottom: 0;
            font-weight: 500;

            .list {
                background-color: $color-white;
                box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
                border-radius: 0;
                margin-top: 0;
                width: 100%;
                padding-top: 10px;
                padding-bottom: 10px;

                .option {
                    transition: 0.5s;
                    padding-left: 20px;
                    padding-right: 20px;

                    &:hover {
                        background-color: $main-color !important;
                        color: $color-white;
                    }

                    &:focus {
                        border: none;
                        outline: none;
                    }

                    .selected {
                        background-color: transparent;
                    }
                }
            }

            &:after {
                right: 20px;
            }
        }
    }

    .form-check {
        margin-bottom: 15px;

        .form-check-input {
            width: 15px;
            height: 15px;
        }

        .form-check-label {
            color: $paragraph-color;
            margin-left: 5px;
            font-weight: 500;
        }
    }
}

.checkout-area .billing-details .form-group .nice-select .option:hover,
.checkout-area .billing-details .form-group .nice-select .option.focus,
.checkout-area .billing-details .form-group .nice-select .option.selected.focus {
    background-color: $main-color !important;
    color: $color-white !important;
    border: none;
    outline: none;
}

.order-details {
    .order-table {
        background-color: $color-white;
        padding: 50px 30px;
        box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);

        h3 {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 20px;
        }

        table {
            margin-bottom: 0;

            thead {
                tr {
                    th {
                        border-bottom-width: 0;
                        vertical-align: middle;
                        border-color: #eaedff;
                        padding-left: 20px;
                        padding-top: 15px;
                        padding-right: 20px;
                        padding-bottom: 15px;
                        font-weight: 600;
                        color: $paragraph-color;
                        font-size: 18px;
                    }
                }
            }

            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        color: $paragraph-color;
                        border-color: #eaedff;
                        font-size: 14px;
                        padding-left: 20px;
                        padding-right: 20px;
                        font-weight: 600;

                        &.product-name {
                            a {
                                color: #6e768f;
                                display: inline-block;
                                font-weight: 600;

                                &:hover {
                                    color: $main-color;
                                }
                            }
                        }

                        &.order-subtotal {
                            span {
                                color: $paragraph-color;
                                font-weight: 600;
                            }
                        }

                        &.order-shipping {
                            span {
                                color: $paragraph-color;
                                font-weight: 700;
                            }
                        }

                        &.total-price {
                            span {
                                color: $paragraph-color;
                                font-weight: 700;
                            }
                        }

                        &.shipping-price {
                            font-weight: 700;
                        }

                        &.order-subtotal-price {
                            font-weight: 700;
                        }

                        &.product-subtotal {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

    .payment-box {
        background-color: $color-white;
        box-shadow: 0 5px 28px rgba(0, 0, 0, 0.07);
        margin-top: 30px;
        margin-bottom: 30px;
        padding: 50px 30px;

        .payment-method {
            p [type="radio"]:checked {
                display: none;
            }

            p [type="radio"]:checked+label {
                padding-left: 27px;
                cursor: pointer;
                display: block;
                font-weight: 600;
                color: $main-color;
                position: relative;
                margin-bottom: 8px;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 3px;
                    width: 18px;
                    height: 18px;
                    border: 1px solid #dddddd;
                    border-radius: 50%;
                    background: $color-white;
                }

                &::after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    background: $main-color;
                    position: absolute;
                    top: 6px;
                    left: 3px;
                    border-radius: 50%;
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                    opacity: 1;
                    visibility: visible;
                    transform: scale(1);
                }
            }

            p [type="radio"]:not(:checked) {
                display: none;
            }

            p [type="radio"]:not(:checked)+label {
                padding-left: 27px;
                cursor: pointer;
                display: block;
                font-weight: 600;
                color: #172541;
                position: relative;
                margin-bottom: 8px;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 3px;
                    width: 18px;
                    height: 18px;
                    border: 1px solid #dddddd;
                    border-radius: 50%;
                    background: $color-white;
                }

                &::after {
                    content: '';
                    width: 12px;
                    height: 12px;
                    background: $main-color;
                    position: absolute;
                    top: 6px;
                    left: 3px;
                    border-radius: 50%;
                    -webkit-transition: 0.5s;
                    transition: 0.5s;
                    opacity: 0;
                    visibility: hidden;
                    transform: scale(0);
                }
            }
        }

        .order-btn {
            margin-top: 20px;
            display: block;
            text-align: center;
            width: 100%;
            padding: 12px 27px;
            color: $color-white;
            text-align: center;
            position: relative;
            overflow: hidden;
            z-index: 1;
            background-color: $main-color;

            &::before {
                content: "";
                position: absolute;
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 100%;
                background-color: #08104d;
                z-index: -1;
                -webkit-transition: all 0.5s;
                transition: all 0.5s;
            }

            &:hover {
                color: $color-white;
                border: none;
            }

            &:hover::before {
                left: auto;
                right: 0;
                width: 100%;
            }
        }
    }
}

.billing-details .form-group .nice-select .option:hover,
.billing-details .form-group .nice-select .option.focus,
.billing-details .form-group .nice-select .option.selected.focus {
    background-color: $main-color !important;
    color: $color-white !important;
}

/*=================================
Checkout Area End
===================================*/

/*==================================
Faq Area
=================================*/
.faq-accordion {
    // max-width: 470px;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 30px;

    .accordion {
        list-style-type: none;
        padding-left: 0;
        margin-bottom: 0;

        .accordion-item {
            display: block;
            background-color: $color-white;
            margin-bottom: 15px;
            box-shadow: 0 0 20px 3px rgba(0, 0, 0, 0.05);

            &:last-child {
                margin-bottom: 0;
            }
        }

        .accordion-title {
            padding: 20px 60px 17px 20px;
            color: #232350;
            text-decoration: none;
            position: relative;
            display: block;
            font-size: 18px;
            font-weight: 600;

            i {
                position: absolute;
                right: 15px;
                top: 15px;
                font-size: 25px;
                transition: 0.5s;
                font-size: 24px;
                width: 40px;
                height: 40px;
                color: $main-color;
                border-radius: 50px;
                border: 1px solid $main-color;
                text-align: center;
                line-height: 40px;
                background-color: transparent;
            }

            &.active i {
                transform: rotate(180deg);
                top: 15px;
            }
        }

        .accordion-content {
            display: none;
            position: relative;
            margin-top: -5px;
            padding-bottom: 10px;
            padding-right: 30px;
            padding-left: 30px;

            p {
                line-height: 1.8;
            }

            &.show {
                display: block;
            }
        }
    }
}

/*==================================
Faq Area End
=================================*/

/*=================================
Achievements Area
====================================*/
.achievements-area {
    .section-title {
        h2 {
            max-width: 415px;
            margin-left: auto;
            margin-right: auto;
        }

        p {
            max-width: 570px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.achievements-card {
    padding: 45px 45px;
    border-radius: 50%;
    text-align: center;
    margin-bottom: 30px;

    i {
        font-size: 60px;
        margin-bottom: 10px;
        line-height: 1.2;
    }

    h3 {
        color: $paragraph-color;
        margin-top: 15px;
        font-size: 30px;
        line-height: 1;
    }

    span {
        font-size: 18px;
        color: $title-color;
    }
}

.achievements-bg-1 {
    background-color: #DCF9EE;

    i {
        color: #46B58B;
    }
}

.achievements-bg-2 {
    background-color: #FEF5E2;

    i {
        color: #A88845;
    }
}

.achievements-bg-3 {
    background-color: #FFE7EA;

    i {
        color: #D66E7B;
    }
}

.achievements-bg-4 {
    background-color: #E0DFEE;

    i {
        color: #5751B4;
    }
}

/*=================================
Achievements Area End
====================================*/

/*=================================
Counter Area
====================================*/
.single-counter {
    margin-bottom: 30px;
    text-align: center;

    i {
        font-size: 50px;
        margin-bottom: 10px;
        line-height: 1.2;
        background-color: $color-deep-sea;
        width: 90px;
        height: 90px;
        line-height: 90px;
        display: inline-block;
        border-radius: 5px;
    }

    h3 {
        color: $paragraph-color;
        margin-top: 15px;
        font-size: 30px;
        line-height: 1;
    }

    span {
        font-size: 18px;
        color: $title-color;
    }
}

.icon-color-1 {
    color: #46B58B;
}

.icon-color-2 {
    color: #A88845;
}

.icon-color-3 {
    color: #D66E7B;
}

.icon-color-4 {
    color: #5751B4;
}

/*=================================
Counter Area End
====================================*/

/*=================================
Project Area
====================================*/
.project-title {
    margin-bottom: 30px;
}

.project-text {
    margin-top: 50px;
    margin-bottom: 30px;

    p {
        margin-bottom: 0;
    }
}

.project-tab {
    margin-top: 10px;

    .tabs {
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            display: inline-block;
            line-height: initial;
            margin-right: 25px;

            a {
                display: inline-block;
                position: relative;
                font-size: 17px;
                font-weight: 600;
                color: $paragraph-color;

                &:focus {
                    color: $main-color;
                }
            }

            &.active a {
                color: $main-color;
            }

            &.current a {
                color: $main-color;
            }
        }
    }
}

.tab {
    .tabs_item {
        display: none;

        &:first-child {
            display: block;
        }
    }
}

.project-card {
    margin-bottom: 30px;
    border-radius: 5px;
    position: relative;
    text-align: center;
    overflow: hidden;

    &:hover {
        .project-content {
            padding: 20px;
            height: auto;
            width: 100%;
            background-color: #17161691;
            bottom: 0;

            .content {
                opacity: 1;
            }
        }

        .project-card-bottom {
            width: 80%;
            opacity: 1;
        }
    }

    a {
        display: block;

        img {
            border-radius: 5px;
        }
    }

    .project-content {
        position: absolute;
        bottom: -150px;
        left: 0;
        right: 0;
        background-color: #17161691;
        padding: 10px 20px;
        height: auto;
        border-radius: 5px;
        transition: 0.9s;
        overflow: hidden;

        h3 {
            font-weight: 400;
            margin-bottom: 0;

            a {
                color: $color-white;
            }
        }

        .content {
            margin-top: 10px;
            margin-bottom: 10px;
            opacity: 0;

            p {
                color: $color-white;
                margin-bottom: 15px;
            }

            .project-more {
                text-align: center;
                line-height: 30px;
                margin: 0 auto;
                font-size: 16px;
                color: $color-white;
                border-radius: 20px;

                &:hover {
                    color: $main-color;
                    text-decoration: underline;
                }
            }
        }
    }

    .project-card-bottom {
        position: absolute;
        bottom: 0;
        width: 0;
        background-color: $main-color;
        opacity: 0;
        transition: 0.7s;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 3px;
        border-radius: 5px;
    }
}

.project-area-two {
    background-color: $color-deep-sea;

    .section-title {
        padding-left: 20px;
        padding-right: 20px;

        h2 {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }

        p {
            max-width: 570px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.single-project {
    position: relative;
    overflow: hidden;

    &:hover {
        a {
            bottom: 0;
        }
    }

    a {
        position: absolute;
        bottom: -70px;
        left: 0;
        width: 100%;
        background-color: $color-white;
        line-height: 1;
        color: $paragraph-color;
        padding: 20px 30px;
        transition: all ease 0.5s;
        font-weight: 600;
        font-size: 22px;
        border-bottom: 1px solid #eeeeee;

        i {
            float: right;
        }
    }
}

.project-details-slider {
    .owl-nav {
        margin-top: 0;

        .owl-prev {
            position: absolute;
            top: 50%;
            transform: translateY(-16px);
            left: -5px;
            color: $color-white !important;
            font-size: 20px !important;
            width: 40px;
            border-radius: 0;
            height: 40px;
            line-height: 40px;
            background-color: $main-color !important;

            &:hover {
                background-color: $paragraph-color !important;
            }
        }

        .owl-next {
            position: absolute;
            top: 50%;
            transform: translateY(-16px);
            right: -5px;
            color: $color-white !important;
            font-size: 20px !important;
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-radius: 0;
            background-color: $main-color !important;

            &:hover {
                background-color: $paragraph-color !important;
            }
        }
    }
}

.project-details-item {
    margin-bottom: 30px;
}

.project-article {
    h2 {
        font-size: 26px;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 20px;
    }

    .blockquote {
        position: relative;
        margin-bottom: 40px;
        margin-top: 40px;
        background-color: $color-deep-sea;
        padding: 30px;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 3px;
            height: 100%;
            text-align: center;
            font-size: 50px;
            font-weight: 400;
            background-color: $main-color;
        }

        p {
            font-size: 20px;
            color: $paragraph-color;
            font-weight: 500;
            margin-top: 10px;
            margin-bottom: 0;
            margin-left: 20px;
        }
    }
}

.project-article-list {
    margin-bottom: 30px;

    h2 {
        font-size: 24px;
        margin-bottom: 15px;
    }

    p {
        margin-bottom: 20px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            margin-bottom: 10px;

            i {
                color: $main-color;
                font-size: 20px;
                position: relative;
                top: 3px;
                margin-right: 10px;
            }
        }
    }
}

.project-side-bar {
    margin-bottom: 30px;
}

.project-categories {
    padding: 10px 15px 20px;
    background-color: $color-deep-sea;
    margin-bottom: 35px;

    h3 {
        font-size: 20px;
        color: $paragraph-color;
        margin-bottom: 10px;
        margin-top: 20px;
        margin-left: 10px;
        padding-bottom: 15px;
        font-weight: 600;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 35%;
            height: 3px;
            background-color: $main-color;
        }
    }

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            position: relative;
            margin-bottom: 10px;
            font-size: 15px;
            font-weight: 500;
            border-bottom: 1px solid #ededed;

            a {
                display: inline-block;
                color: $paragraph-color;
                font-weight: normal;
                padding: 7px 20px;
                font-weight: 500;

                &:hover {
                    color: $main-color;
                }
            }

            span {
                padding: 7px 15px;
                float: right;
                color: #646060;
                font-weight: 500;
            }
        }
    }
}

.side-bar-from {
    background-color: $color-deep-sea;
    padding: 30px 20px;
    margin-bottom: 35px;

    h3 {
        font-size: 20px;
        margin-bottom: 20px;
        position: relative;
        padding-bottom: 15px;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 35%;
            height: 3px;
            background-color: $main-color;
        }
    }

    .appointment-form {
        margin-bottom: 0;
        margin-right: 0;

        .form-group {
            .form-control {
                background-color: $color-white;
                border-radius: 0;
            }
        }

        .default-btn {
            padding: 10px 20px;
            border-radius: 0;
            font-size: 14px;
            font-weight: 500;
        }
    }
}

/*=================================
Project Area End
====================================*/

/*=================================
Testimonials Area
====================================*/
.testimonials-area {
    position: relative;

    .section-title {
        h2 {
            max-width: 415px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .owl-dots {
        margin-top: 0px !important;
        margin-bottom: 30px;
        left: 35px !important;
        position: absolute;
        bottom: 65px;

        .owl-dot {
            span {
                background-color: transparent !important;
                width: 15px !important;
                height: 15px !important;
                transition: 0.7s;
                border: 1px solid $main-color;
                position: relative;

                &::before {
                    content: '';
                    width: 8px;
                    height: 8px;
                    position: absolute;
                    background-color: $main-color;
                    top: 2.9px;
                    left: 1px;
                    right: 1px;
                    text-align: center;
                    margin: 0 auto;
                    border-radius: 50px;
                    opacity: 0;
                }
            }

            &.active span::before {
                opacity: 1;
            }

            &:hover span::before {
                opacity: 1;
            }
        }
    }
}

.testimonials-img {
    margin-right: 20px;
    margin-bottom: 30px;
    position: relative;
    margin-top: 35px;

    img {
        border-radius: 15px;
    }

    .quote-text {
        position: absolute;
        top: -25px;
        left: -25px;
        background-color: $main-color;
        width: 70px;
        height: 70px;
        font-size: 35px;
        color: $color-white;
        line-height: 70px;
        text-align: center;
        border-radius: 50px;
    }
}

.testimonials-content {
    margin-left: 40px;

    h3 {
        margin-bottom: 5px;
    }

    span {
        font-size: 16px;
        color: #838383;
    }

    p {
        margin-top: 10px;
        font-size: 18px;
        font-weight: 500;
        color: #838383;
        margin-bottom: 40px;
    }
}

.testimonials-bg {
    background-color: $color-deep-sea;
}

/*=================================
Testimonials Area End
====================================*/

/*=================================
Team Area
====================================*/
.team-area {
    position: relative;

    .section-title {
        h2 {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }

        p {
            max-width: 560px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    .owl-nav {
        margin-top: 0;

        .owl-prev {
            position: absolute;
            top: 50%;
            transform: translateY(-16px);
            left: -5%;
            color: $color-white !important;
            font-size: 20px !important;
            width: 40px;
            border-radius: 50px;
            height: 40px;
            line-height: 40px;
            background-color: #707070 !important;

            &:hover {
                background-color: $main-color !important;
            }
        }

        .owl-next {
            position: absolute;
            top: 50%;
            transform: translateY(-16px);
            right: -5%;
            color: $color-white !important;
            font-size: 20px !important;
            width: 40px;
            height: 40px;
            line-height: 40px;
            border-radius: 50px;
            background-color: #707070 !important;

            &:hover {
                background-color: $main-color !important;
            }
        }
    }
}

.team-item {
    margin-bottom: 30px;
    background-color: $color-white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    border-radius: 5px;

    &:hover {
        .team-img {
            img {
                filter: brightness(0.5);
            }

            .social-icon {
                .social-link {
                    li {
                        a {
                            transform: scaleY(1);
                        }
                    }
                }
            }
        }
    }

    .team-img {
        position: relative;

        img {
            border-radius: 5px;
        }

        .social-icon {
            position: absolute;
            right: 0;
            left: 0;
            text-align: center;
            margin: 0 auto;
            bottom: 40px;

            .social-link {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    display: inline-block;
                    margin-right: 5px;

                    a {
                        border-radius: 3px;
                        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
                        width: 35px;
                        height: 35px;
                        margin: 0 auto;
                        line-height: 37px;
                        text-align: center;
                        transform: scaleY(0);
                        color: $paragraph-color;
                        background-color: $color-white;
                        transition: 0.7s;

                        &:hover {
                            background-color: $main-color;
                            color: $color-white;
                            border-radius: 50px;
                        }
                    }
                }
            }
        }
    }

    .content {
        padding: 30px 20px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            top: 35px;
            left: 0px;
            width: 3px;
            height: 45px;
            background-color: $main-color;
        }

        h3 {
            font-size: 20px;
            margin-bottom: 5px;
        }

        span {
            color: #6e607c;
        }
    }
}

/*=================================
Team Area End
====================================*/

/*=================================
Blog Area
====================================*/
.blog-area {
    .section-title {
        h2 {
            max-width: 450px;
            margin-left: auto;
            margin-right: auto;
        }

        p {
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.blog-card {
    background-color: #F2F2F5;
    margin-bottom: 30px;

    &:hover {
        .content {
            h3 {
                a {
                    color: $main-color;
                }
            }

            .read-more-btn {
                color: $paragraph-color;
                letter-spacing: 0.25px;
            }
        }
    }

    a {
        display: block;

        img {
            max-width: 100%;
        }
    }

    .blog-img {
        position: relative;

        .date {
            position: absolute;
            bottom: -1px;
            left: 0;
            background-color: $main-color;
            padding: 7px 30px 7px 20px;
            font-weight: 600;
            font-size: 15px;
            color: $color-white;
            clip-path: polygon(0 0, 88% 0%, 100% 100%, 0% 100%);
        }
    }

    .content {
        padding: 20px;

        span {
            font-weight: 500;
            color: $title-color;
            position: relative;
            margin-left: 30px;

            &::before {
                content: '';
                position: absolute;
                bottom: 3px;
                left: -30px;
                width: 30px;
                height: 1px;
                background-color: $main-color;
            }
        }

        h3 {
            margin-top: 5px;
            margin-bottom: 10px;
            font-size: 22px;

            a {
                display: block;
                color: $title-color;
            }
        }

        p {
            margin-bottom: 15px;
        }

        .read-more-btn {
            color: $title-color;
            font-weight: 600;
            display: block;
        }
    }
}

.blog-card-bg {
    background-color: $color-white;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    transition: 0.7s;

    &:hover {
        transform: translateY(-10px);
    }
}

.blog-article {
    margin-bottom: 30px;

    .blog-article-img {
        margin-bottom: 30px;
    }

    .blog-status {
        padding-bottom: 20px;
        border-bottom: 2px solid $color-deep-sea;
        margin-bottom: 30px;

        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                color: $paragraph-color;
                margin-right: 30px;
                position: relative;

                &::before {
                    content: '';
                    position: absolute;
                    top: 3px;
                    width: 1px;
                    height: 20px;
                    background-color: #e0dde3;
                    right: -20px;
                }

                &:last-child {
                    margin-right: 0;

                    &::before {
                        display: none;
                    }
                }

                a {
                    color: $main-color;
                    display: inline-block;

                    &:hover {
                        color: $paragraph-color;
                    }
                }
            }
        }

        .blog-comment {
            float: right;

            h3 {
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 0;

                i {
                    color: $main-color;
                    margin-right: 5px;
                    position: relative;
                    top: 2px;
                }
            }
        }
    }

    .article-content {
        h2 {
            font-size: 26px;
            margin-bottom: 20px;
        }

        p {
            margin-bottom: 20px;
        }

        .blockquote {
            position: relative;
            margin-bottom: 40px;
            margin-top: 40px;
            background-color: $color-deep-sea;
            padding: 30px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 3px;
                height: 100%;
                text-align: center;
                font-size: 50px;
                font-weight: 400;
                background-color: $main-color;
            }

            p {
                font-size: 20px;
                color: $paragraph-color;
                font-weight: 500;
                margin-top: 10px;
                margin-bottom: 0;
                margin-left: 20px;
            }
        }
    }

    .another-content {
        p {
            margin-bottom: 20px;
        }

        .content-img {
            margin-top: 30px;
            margin-bottom: 30px;
        }
    }

    .blog-article-share {
        margin-top: 30px;
        border-bottom: 1px solid #e0dde3;
        padding-bottom: 30px;

        .social-icon {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                margin-right: 5px;
                color: $paragraph-color;

                &:first-child {
                    margin-right: 15px;
                    font-weight: 600;
                }

                a {
                    width: 30px;
                    height: 30px;
                    line-height: 32px;
                    text-align: center;
                    background-color: $main-color;
                    color: $color-white;
                    border-radius: 3px;

                    &:hover {
                        background-color: $paragraph-color;
                        color: $color-white;
                    }
                }
            }
        }
    }

    .blog-profile {
        margin-top: 30px;
        padding-top: 20px;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                position: relative;
                padding: 0 30px 30px;
                padding-left: 170px;

                img {
                    border-radius: 50%;
                    position: absolute;
                    top: 0;
                    left: 30px;
                }

                h3 {
                    margin-bottom: 0;
                    font-size: 20px;
                    font-weight: 500;
                }

                p {
                    margin-bottom: 0;
                }
            }
        }
    }

    .comments-wrap {
        padding-top: 20px;

        .title {
            font-size: 24px;
            margin-bottom: 30px;
            color: $paragraph-color;
            font-weight: 500;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

            li {
                position: relative;
                padding: 30px;
                padding-left: 140px;
                margin-bottom: 30px;
                background-color: $color-deep-sea;

                &:last-child {
                    margin-bottom: 0;
                }

                img {
                    border-radius: 50%;
                    position: absolute;
                    top: 30px;
                    left: 30px;
                }

                h3 {
                    margin-bottom: 0;
                    font-size: 20px;
                    font-weight: 500;
                }

                span {
                    margin-bottom: 10px;
                    display: block;
                    font-size: 14px;
                    color: #8b8b8b;
                }

                p {
                    margin-bottom: 0;
                }

                a {
                    position: absolute;
                    top: 27px;
                    right: 30px;
                    color: $main-color;
                    font-weight: 600;

                    &:hover {
                        color: $paragraph-color;
                    }
                }
            }
        }
    }

    .comments-form {
        margin-top: 40px;

        .contact-form {
            .form-group {
                .form-control {
                    border-color: #988ea1;
                    background-color: transparent;
                    border-radius: 0;
                }
            }

            .default-btn {
                border-radius: 0;
            }
        }
    }
}

.side-bar-wrap {
    margin-bottom: 30px;
}

.side-bar-widget {
    margin-bottom: 30px;
}

.search-widget {
    margin-bottom: 30px;
    background-color: $color-deep-sea;
    padding: 20px;

    .search-form {
        position: relative;

        .form-control {
            height: 50px;
            border: 1px solid #988ea1;
            background-color: $color-white;
            padding: 10px 20px;
            width: 100%;
            border-radius: 0;

            &:focus {
                box-shadow: none;
                outline: 0;
            }
        }

        button {
            position: absolute;
            top: 0;
            right: 0;
            height: 50px;
            width: 50px;
            background-color: $main-color;
            transition: all 0.5s;
            border: none;
            outline: none;

            i {
                color: $color-white;
            }

            &:hover {
                background-color: $paragraph-color;
            }
        }
    }
}

.side-bar-widget {
    margin-bottom: 30px;
    background-color: $color-deep-sea;

    .title {
        font-size: 20px;
        color: $paragraph-color;
        padding: 15px 0;
        font-weight: 600;
        position: relative;
        display: inline-block;
        margin-left: 30px;
        border-bottom: 3px solid $main-color;
    }

    .side-bar-categories {
        padding: 10px 15px 20px;

        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 10px;
                font-size: 15px;
                font-weight: 500;
                border-bottom: 1px solid #ededed;

                a {
                    display: inline-block;
                    color: $paragraph-color;
                    font-weight: normal;
                    padding: 7px 20px;
                    font-weight: 500;

                    &:hover {
                        color: $main-color;
                    }
                }

                span {
                    padding: 7px 15px;
                    float: right;
                    color: $main-color;
                    font-weight: 500;
                }
            }
        }
    }

    .widget-popular-post {
        position: relative;
        overflow: hidden;
        padding: 20px 30px 30px;

        .item {
            overflow: hidden;
            margin-bottom: 10px;
            padding-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
                border-bottom: none;
                padding-bottom: 0;
            }

            .thumb {
                float: left;
                overflow: hidden;
                position: relative;
                margin-right: 15px;

                .full-image {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    background-size: cover !important;
                    background-repeat: no-repeat;
                    background-position: center center !important;
                    position: relative;
                    background-color: $paragraph-color;

                    &.bg1 {
                        background-image: url(assets/img/blog/blog-img1.jpg);
                    }

                    &.bg2 {
                        background-image: url(assets/img/blog/blog-img2.jpg);
                    }

                    &.bg3 {
                        background-image: url(assets/img/blog/blog-img3.jpg);
                    }
                }
            }

            .info {
                overflow: hidden;

                .title-text {
                    margin-bottom: 5px;
                    line-height: 1.5;
                    font-size: 18px;
                    font-weight: 500;

                    a {
                        display: inline-block;
                        color: $paragraph-color;

                        &:hover {
                            color: $main-color;
                        }
                    }
                }

                p {
                    font-size: 14px;
                    margin-bottom: 0;
                    max-width: 180px;
                }
            }
        }
    }

    .side-bar-widget-tag {
        list-style: none;
        margin: 0;
        padding: 10px 20px 20px;

        li {
            display: inline-block;
            padding: 7px 15px;
            margin: 5px;
            transition: 0.7s;
            color: $paragraph-color;
            font-size: 14px;
            font-weight: 600;
            border: 1px solid #cbcbcb;

            &:hover {
                background-color: $main-color;
                border-color: $main-color;
            }

            a {
                color: $paragraph-color;
            }

            &:hover a {
                color: $color-white;
            }
        }
    }
}

.subscribe-area {
    background-color: $color-deep-sea;
    padding: 30px;

    span {
        color: $main-color;
        font-size: 13px;
    }

    h2 {
        font-size: 20px;
        margin-top: 5px;
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 15px;

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 35%;
            height: 3px;
            background-color: $main-color;
        }
    }

    .subscribe-form {
        position: relative;
        border-radius: 50px;

        .form-control {
            background: $color-white;
            color: $paragraph-color;
            height: 50px;
            line-height: 50px;
            margin: 0;
            border-radius: 0;
            border: 1px solid #cdcdcd;
            padding: 0 25px;
            width: 90%;

            &:focus {
                outline: none;
                border: none;
                box-shadow: none;
            }
        }

        .default-btn {
            margin-top: 15px;
            outline: none;
            border: 0;
            border-radius: 0;
            padding: 10px 20px;
            font-size: 14px;
            font-weight: 600;
        }
    }
}

/*=================================
Blog Area End
====================================*/

/*=================================
Contact Area
====================================*/
.contact-form {
    max-width: 800px;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;

    .section-title {
        margin-bottom: 30px;

        p {
            max-width: 500px;
        }
    }

    .form-group {
        margin-bottom: 25px;
        position: relative;

        .form-control {
            height: 50px;
            color: #948b9f;
            border: 1px solid #ebebeb;
            background-color: #f8f9fb;
            font-size: 14px;
            padding: 10px 20px;
            width: 100%;

            &:focus {
                outline: none;
                border-color: $main-color;
                box-shadow: none;
            }
        }

        textarea.form-control {
            height: auto;
        }
    }

    .default-btn {
        border: 0;
        outline: none;
        padding: 14px 60px;
    }
}

.contact-form .form-group .form-control::-webkit-input-placeholder {
    color: #948b9f;
}

.contact-form .form-group .form-control:-ms-input-placeholder {
    color: #948b9f;
}

.contact-form .form-group .form-control::-ms-input-placeholder {
    color: #948b9f;
}

.contact-form .form-group .form-control::placeholder {
    color: #948b9f;
}

.contact-sidebar {
    padding: 50px 25px 20px;
    background-color: $color-deep-sea;

    h2 {
        font-size: 25px;
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 30px;
    }
}

.contact-card {
    position: relative;
    margin-bottom: 30px;

    i {
        color: $main-color;
        font-size: 45px;
        position: absolute;
        left: 0;
        top: 0;
    }

    .content {
        margin-left: 65px;

        h3 {
            font-size: 22px;
            margin-bottom: 10px;
            font-weight: 500;
        }

        p {
            margin-bottom: 0;
            color: $title-color;

            a {
                display: block;
                color: $title-color;

                &:hover {
                    color: $main-color;
                }
            }
        }

        span {
            margin-bottom: 0;
            color: $title-color;

            a {
                display: block;
                color: $title-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
}

/*=================================
Contact Area End
====================================*/

/*=================================
User All Form
====================================*/
.user-all-form {
    margin-bottom: 30px;

    .contact-form {
        background-color: $color-white;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
        padding: 50px 30px;
        max-width: 700px;
        margin-left: auto;
        margin-right: auto;

        .agree-label {
            label {
                font-weight: 500;
                color: $paragraph-color;
                margin-left: 10px;
            }
        }

        .forget {
            margin-bottom: 15px;
            float: right;
            color: $paragraph-color;
            font-weight: 500;

            &:hover {
                color: $main-color;
            }
        }

        .account-desc {
            margin-top: 15px;
            font-weight: 600;
            text-align: center;
            margin-bottom: 0;

            a {
                color: $paragraph-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
}

/*=================================
User All Form End
====================================*/

/*=================================
Map Area
====================================*/
.map-area iframe {
    display: block;
    width: 100%;
    height: 400px;
    border: none;
}

/*=================================
Map Area End
====================================*/
.single-content {
    margin-bottom: 30px;

    h3 {
        font-size: 24px;
        color: $paragraph-color;
        margin-bottom: 10px;
    }

    p {
        margin-bottom: 0;
    }
}

/*=================================
Appointment Area
====================================*/
.appointment-bg {
    background-color: $color-deep-sea;
}

.appointment-form {
    margin-bottom: 30px;
    margin-right: 45px;

    .section-title {
        margin-bottom: 30px;

        p {
            max-width: 615px;
            margin: 0;
        }
    }

    .form-group {
        margin-bottom: 25px;
        position: relative;

        .form-control {
            height: 50px;
            color: #948b9f;
            border: 1px solid #ebebeb;
            background-color: #f8f9fb;
            font-size: 14px;
            padding: 10px 20px;
            width: 100%;

            &:focus {
                outline: none;
                border-color: $main-color;
                box-shadow: none;
            }
        }

        textarea.form-control {
            height: auto;
        }
    }

    .nice-select {
        height: 50px;
        width: 100%;
        line-height: 32px;
        font-size: 15px;
        margin-bottom: 30px;
        padding-left: 20px;
        color: $paragraph-color;
        background-color: $main-color;

        .list {
            background-color: $color-white;
            box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
            border-radius: 0;
            margin-top: 0;
            width: 100%;
            padding-top: 10px;
            padding-bottom: 10px;

            .option {
                -webkit-transition: .6s;
                transition: .6s;
                color: $paragraph-color;
                padding-left: 20px;
                padding-right: 20px;

                &:hover {
                    background-color: $main-color !important;
                    color: $color-white;
                }

                .selected {
                    background-color: transparent;
                    font-weight: 600;
                    color: $paragraph-color;
                }
            }
        }

        &::after {
            height: 8px;
            width: 8px;
            border-color: #a7a7a7;
            right: 23px;
        }
    }

    .default-btn {
        border: 0;
        outline: none;
        padding: 14px 60px;
    }
}

.appointment-form-bg {
    .form-group {
        .form-control {
            background-color: $color-white;
        }
    }
}

.appointment-img {
    position: relative;
    z-index: 1;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 50px;

    img {
        border-radius: 0 50px 0 50px;
    }

    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: -20px;
        left: -20px;
        width: 200px;
        height: 200px;
        background-color: $main-color;
    }

    &::after {
        content: '';
        position: absolute;
        z-index: -1;
        bottom: -20px;
        right: -20px;
        width: 200px;
        height: 200px;
        background-color: $main-color;
    }
}

/*=================================
Appointment Area End
====================================*/

/*==============================
Pagination Area
=================================*/
.pagination-area {
    margin-top: 10px;
    margin-bottom: 30px;
    text-align: center;

    .page-numbers {
        width: 40px;
        height: 40px;
        line-height: 40px;
        color: $color-white;
        background-color: $paragraph-color;
        text-align: center;
        display: inline-block;
        position: relative;
        margin-left: 3px;
        margin-right: 3px;
        font-size: 18px;
        border-radius: 5px;

        &:hover {
            background-color: $main-color;
        }

        i {
            position: relative;
            font-size: 25px;
            top: 5px;
        }

        &.current {
            background-color: $main-color;
        }
    }
}

/*==============================
Pagination Area End
=================================*/

/*=================================
404 Error Area
===================================*/
.error-area {
    border-top: 1px solid #eeeeee;

    .error-content {
        text-align: center;

        h1 {
            font-size: 300px;
            line-height: 0.7;
            font-weight: 700;
            color: $paragraph-color;

            span {
                color: $main-color;
            }
        }

        h3 {
            margin: 50px 0 0;
            position: relative;
            color: $paragraph-color;
            font-size: 35px;
        }

        p {
            margin-top: 20px;
            margin-bottom: 20px;
            font-size: 18px;
            max-width: 520px;
            margin-left: auto;
            margin-right: auto;
            color: $paragraph-color;
        }
    }
}

/*=================================
404 Error Area End
===================================*/

/*==================================
Coming Soon Area
====================================*/
.coming-soon-area {
    position: relative;
    height: 100vh;
    overflow: hidden;
    background-image: url(assets/img/inner-banner/inner-banner-11.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .coming-soon-content {
        text-align: center;
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        z-index: 2;
        padding: 50px 30px;
        background-color: $color-deep-sea;
        border: 3px solid #fc8e41a3;

        h1 {
            margin-bottom: 0;
            color: $paragraph-color;
            font-size: 60px;
        }

        p {
            font-size: 16px;
            max-width: 600px;
            margin-top: 15px;
            margin-bottom: 0;
            margin-left: auto;
            margin-right: auto;
            color: #767079;
        }

        #timer {
            margin-top: 20px;

            div {
                display: inline-block;
                color: $paragraph-color;
                position: relative;
                margin-left: 35px;
                margin-right: 35px;
                font-size: 45px;
                font-weight: 700;

                span {
                    display: block;
                    text-transform: capitalize;
                    margin-top: -15px;
                    font-size: 16px;
                    font-weight: normal;
                    color: $paragraph-color;
                }

                &:last-child {
                    margin-right: 0;

                    &::before {
                        display: none;
                    }
                }

                &:first-child {
                    margin-left: 0;
                }

                &::before {
                    content: "";
                    position: absolute;
                    right: -50px;
                    top: -10px;
                    font-size: 70px;
                    color: $color-white;
                }
            }
        }

        .newsletter-form {
            position: relative;
            max-width: 500px;
            margin-left: auto;
            margin-right: auto;
            margin-top: 50px;

            .input-newsletter {
                display: block;
                width: 100%;
                height: 60px;
                border: none;
                background-color: $color-white;
                padding-left: 15px;
                color: $color-white;
                outline: 0;
                transition: 0.5s;
                border-radius: 0;
                border: 1px solid #cccccc;
                color: #5d5d5d;

                &:focus {
                    border-color: $main-color;
                }
            }

            .default-btn {
                border: 0;
                outline: 0;
                border-radius: 0 !important;
            }

            button {
                position: absolute;
                right: 0;
                top: 0;
                height: 60px;
                padding: 0 30px;
                text-transform: uppercase;
                outline: 0;
                color: $color-white;
                transition: 0.5s;
                font-weight: 500;
                font-size: 15px;
                cursor: pointer;

                &::after {
                    border-radius: 0;
                }

                &::before {
                    border-radius: 0;
                }

                &:hover {
                    color: $color-white;
                    background-color: #190f3c;
                }
            }
        }

        ul {
            list-style: none;
            margin-top: 30px;
            padding: 0;

            li {
                display: inline-block;
                width: 45px;
                height: 45px;
                line-height: 50px;
                font-size: 18px;
                background-color: $main-color;
                color: $color-white;
                border-radius: 50px;
                margin-right: 10px;

                a {
                    color: $color-white;
                }

                &:hover {
                    background-color: $paragraph-color;
                }
            }
        }
    }
}

/*==================================
Coming Soon Area End
====================================*/

/*=================================
Footer Area
====================================*/
.footer-area {
    background-color: #F2F2F5;
}

.footer-widget {
    margin-bottom: 30px;

    .footer-logo {
        margin-bottom: 30px;
    }

    h3 {
        margin-top: 30px;
        font-size: 24px;
        color: $paragraph-color;
        margin-bottom: 30px;
    }

    p {
        margin-bottom: 20px;
    }

    .footer-list {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            color: $paragraph-color;
            margin-bottom: 5px;

            a {
                color: $paragraph-color;

                &:hover {
                    color: $main-color;
                    letter-spacing: 0.15px;
                }
            }
        }
    }

    .footer-list-two {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            display: block;
            color: $paragraph-color;
            margin-bottom: 7px;
            position: relative;
            padding-left: 30px;

            i {
                color: $main-color;
                margin-right: 5px;
                position: absolute;
                left: 0;
                top: 3px;
                font-size: 20px;
            }

            a {
                color: $paragraph-color;
            }

            &:hover {
                color: $main-color;

                i {
                    color: $paragraph-color;
                }

                a {
                    color: $main-color;
                }
            }
        }
    }

    .newsletter-form {
        position: relative;
        border-radius: 50px;

        .form-control {
            background: $paragraph-color;
            color: $color-white;
            height: 50px;
            line-height: 50px;
            margin: 0;
            border-radius: 5px;
            border: none;
            padding: 0 25px;
            width: 90%;

            &:focus {
                outline: none;
                border: none;
                box-shadow: none;
            }
        }

        .default-btn {
            margin-top: 15px;
            outline: none;
            border: 0;
            padding: 12px 50px;
        }
    }
}

.footer-widget .newsletter-form .form-control::-webkit-input-placeholder {
    color: #948b9f;
}

.footer-widget .newsletter-form .form-control:-ms-input-placeholder {
    color: #948b9f;
}

.footer-widget .newsletter-form .form-control::-ms-input-placeholder {
    color: #948b9f;
}

.footer-widget .newsletter-form .form-control::placeholder {
    color: #948b9f;
}

.copy-right-area {
    padding: 15px;
    background-color: $paragraph-color;

    .copy-right-text {
        p {
            color: $color-white;
            margin-bottom: 0;

            a {
                color: $main-color;
                border-bottom: 1px solid $main-color;

                &:hover {
                    color: $color-white;
                    border-color: $color-white;
                }
            }
        }
    }
}

/*=================================
Footer Area End
====================================*/

/*==================================
Back To Top Button
=====================================*/
#toTop {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    display: none;
    z-index: 99;
}

.top-btn {
    background-color: $main-color;
    color: $color-white;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    box-shadow: 0 0 15px $main-color;
    font-size: 20px;
    display: inline-block;
    text-align: center;
    line-height: 45px;
    transition: .9s;

    &:hover {
        background-color: $paragraph-color;
        box-shadow: 0 0 15px #08104d;
        color: $color-white;
    }
}

/*==============================
Back To Top Button
=================================*/

/*==================================
Preloader CSS
=====================================*/
.preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: $paragraph-color;
}

.spinner {
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background-color: $color-white;
    margin: 100px auto;
    animation: rotate-in 1.2s infinite ease-in-out;
}

@-webkit-keyframes rotate-in {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

@keyframes rotate-in {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }

    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }

    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

/*==================================
Preloader CSS End
=====================================*/

.new-curosal-view .owl-stage {
    width: unset !important;
}

.new-curosal-view .owl-carousel .owl-item {
    width: 21% !important;
}

.testimonials-area .owl-dots {
    bottom: 0 !important;
}

.flaticon-left-quote,
i.bx.bxs-quote-left {
    position: relative;
    top: -18px;
}

.testimonials-img .quote-text {
    position: absolute;
    top: -11px !important;
    left: -15px !important;
    background-color: #FC8E41;
    width: 30px !important;
    height: 30px !important;
    font-size: 22px;
    color: #ffffff;
    line-height: 70px;
    text-align: center;
    border-radius: 50px;
}

.service-card .content {
    margin-top: 0 !important;
}

.about-img img {
    height: 300px;
    width: 100%;
    object-fit: cover;
    object-position: left center
}

.service-item {
    min-height: 428px !important;
    margin: 0 !important;
}

// .about-text h2, .about-text p{
//     color: #fff !important;
// }
.pb-45 {
    padding-bottom: 45px;
}

/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #FC8E41;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #f2f2f2;
}

/*=================================
Order Submit
====================================*/

.order-inner-banner {
    position: relative;
    background-position: center center;
    background-size: cover;

    padding: {
        top: 110px;
        bottom: 100px;
    }

    ;

    &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        opacity: 0.65;
    }

    .inner-title {
        h3 {
            font-size: 40px;
            color: #000000;
            font-weight: 700;
            position: relative;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            position: relative;

            li {
                font-size: 17px;
                color: $color-white;
                display: inline-block;

                i {
                    color: $color-white;
                    position: relative;
                    top: 3px;
                    margin: 0 3px;
                }

                a {
                    color: $color-white;

                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
}

.order-default-btn {
    padding: 14px 27px;
    background-color: #8A2BE2;
    color: $color-white;
    border-radius: 5px;
    position: relative;
    display: inline-block;
    border: none;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 0;
        background-color: $paragraph-color;
        opacity: 0;
        transition: 0.7s;
        border-radius: 5px;
    }

    &:hover {
        background-color: $paragraph-color;
        color: $color-white;

        &::before {
            height: 100%;
            opacity: 1;
            border-radius: 5px;
        }
    }
}

.mat-mdc-snack-bar-container {
    &.success {
        --mdc-snackbar-container-color: #1fb811;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
    }
}

.mat-mdc-snack-bar-container {
    &.failed {
        --mdc-snackbar-container-color: #a80909;
        --mat-mdc-snack-bar-button-color: #fff;
        --mdc-snackbar-supporting-text-color: #fff;
    }
}

.mat-mdc-snack-bar-container {
    &.warning {
        --mdc-snackbar-container-color: #dfc117;
        --mat-mdc-snack-bar-button-color: #000;
        --mdc-snackbar-supporting-text-color: #000;
    }
}